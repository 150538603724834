body {
    --grey-color: #e3e3ca;
    --neutral-color: #fafafa;
    --background-color: #fff;

    --danger-color: #e13b3b;
    --warning-color: #e2a03f;
    --success-color: #009688;

    --action-color: #7f3f97;
    --accent-color: #034ea1;
    --border-color: #e0e6ed;

    --font-family: 'Open Sans', sans-serif;
    --font-color-heading: #3b3f5c;
    --font-color: #72778d;

    --midlarge-font-size: 16px;
    --large-font-size: 23px;
    --normal-font-size: 13px;
    --small-font-size: 12px;

    --line-height: 1.5;
    --letter-spacing: 0.3px;
}

body[font-size='large'] {
    --midlarge-font-size: 19px;
    --large-font-size: 26px;
    --normal-font-size: 16px;
    --small-font-size: 15px;
}

body[font-size='very large'] {
    --midlarge-font-size: 22px;
    --large-font-size: 29px;
    --normal-font-size: 19px;
    --small-font-size: 18px;
}

body[line-height='large'] {
    --line-height: 1.8;
}
body[line-height='very large'] {
    --line-height: 2.1;
}

body[text-spacing='large'] {
    --letter-spacing: 1px;
}
body[text-spacing='very large'] {
    --letter-spacing: 1.5px;
}

body[saturation='low'] {
    filter: saturate(0.5);
}
body[saturation='high'] {
    filter: saturate(2);
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: var(--font-family);
    width: 100%;
    height: 100% !important;
    overflow: hidden;
}

html {
    font-size: 62.5%;
}
body {
    background-color: var(--neutral-color);
}

#root {
    height: 100%;
}

h1 {
    font-weight: 700;
    margin: 0;
    color: var(--font-color-heading);
    font-size: var(--midlarge-font-size);
    font-family: var(--font-family);
    line-height: var(--line-height);
    letter-spacing: var(--letter-spacing);
}
h2 {
    font-weight: 700;
    margin: 0;
    color: var(--font-color-heading);
    font-family: var(--font-family);
    line-height: var(--line-height);
    letter-spacing: var(--letter-spacing);
}
h3 {
    font-weight: 700;
    margin: 0;
    color: var(--font-color-heading);
    font-family: var(--font-family);
    line-height: var(--line-height);
    letter-spacing: var(--letter-spacing);
}

p,
span,
div,
input,
button {
    font-weight: 400;
    color: var(--font-color);
    font-size: var(--normal-font-size);
    font-family: var(--font-family);
    line-height: var(--line-height);
    letter-spacing: var(--letter-spacing);
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-indent: 0;
}

/* Snackbar */
#notistack-snackbar {
    color: #fff;
    width: 100%;
}
#notistack-snackbar span {
    color: #fff;
}

.notistack-MuiContent {
    flex-wrap: nowrap !important;
}

.notistack-MuiContent-error {
    background-color: var(--danger-color) !important;
}
.notistack-MuiContent-success {
    background-color: var(--success-color) !important;
}
.notistack-MuiContent-warning {
    background-color: var(--warning-color) !important;
}
.notistack-MuiContent-info {
    background-color: var(--action-color) !important;
}

.dismissNotistack {
    width: 20px;
    cursor: pointer;
    color: #fff;
}

.LearnMoreError {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.LearnMoreButton {
    margin-left: auto;
    padding: 5px;
    border: solid 1px #fff;
}

.leaflet-control-attribution {
    display: none;
}
