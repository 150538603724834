.Page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.PageContent {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 40px;
}

.Title {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: solid 1px var(--font-color-heading);
    padding-bottom: 20px;
    margin-bottom: 15px;
}
.Title svg {
    width: 30px;
}
.Title span {
    color: var(--font-color-heading);
    font-weight: 700;
}

.Multiple {
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: 38px;
}
.Multiple > span {
    color: var(--font-color-heading);
    font-weight: 700;
}
.Multiple .Options {
    display: flex;
    align-items: center;
    gap: 15px;
}
.Multiple .Options > span {
    cursor: pointer;
}
.Multiple .Options > span:nth-child(1) {
    font-size: 13px;
}
.Multiple .Options > span:nth-child(2) {
    font-size: 16px;
}
.Multiple .Options > span:nth-child(3) {
    font-size: 19px;
}
.Multiple .Options > span.Active {
    color: var(--action-color);
    font-weight: 700;
}

.LineOptions {
    display: flex;
    align-items: center;
    gap: 15px;
}
.LineOptions svg {
    width: 13px;
    cursor: pointer;
}
.LineOptions svg.Active {
    fill: var(--action-color);
}

.TextOptions {
    display: flex;
    align-items: center;
    gap: 15px;
}
.TextOptions svg {
    height: 13px;
    cursor: pointer;
}
.TextOptions svg.Active {
    fill: var(--action-color);
}

.SaturationOptions {
    display: flex;
    align-items: center;
    gap: 15px;
}
.SaturationOptions svg {
    width: 16px;
    cursor: pointer;
}
.SaturationOptions svg.Active {
    fill: var(--action-color);
}
.SaturationOptions svg:nth-child(1) {
    opacity: 0.3;
}
.SaturationOptions svg:nth-child(2) {
    opacity: 0.6;
}

.ButtonWrapper {
    margin-top: 10px;
}
