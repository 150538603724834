.Row {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.Row span:nth-child(1) {
    flex: 1;
}

.IconWrapper {
    flex: 0 0 50px;
    display: flex;
    justify-content: center;
}

.Icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Icon svg {
    height: 14px;
    fill: #fff;
}

.IconView {
    background-color: var(--accent-color);
}
.IconMap {
    background-color: var(--action-color);
}
