.Logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Logo img {
    width: 100px;
    cursor: pointer;
}
