.Page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.PageContent {
    padding: 20px;
    margin-bottom: 40px;
}

.TitleRow {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.TitleRow span {
    font-weight: 700;
    color: var(--font-color-heading);
}
.TitleRow span:first-child {
    flex: 1;
}

.TitleRow span:nth-child(2) {
    flex: 0 0 50px;
    text-align: center;
}
.TitleRow span:nth-child(3) {
    flex: 0 0 50px;
    text-align: center;
}
