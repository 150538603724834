.MainRadioWrapper {
    padding: 5px 0;
}

.MainRadio {
    position: relative;
    cursor: pointer;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
}

.MainRadio input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.MainRadioIndicator {
    display: block;
    width: 16px;
    height: 16px;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #e0e6ed;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    border-radius: 50%;
}

.MainRadio input:checked ~ span.MainRadioIndicator {
    background: var(--action-color);
}

.MainRadioText {
    margin-left: 10px;
    font-weight: 700;
}
