.NavLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 18px;
    flex: 1;
    background-color: #fff;
    transition: background-color 0.3s ease-in;
}
.NavLink span {
    color: var(--font-color);
    font-weight: 700;
    transition: color 0.3s ease-in;
}
.NavLink svg {
    display: block;
    height: 18px;
    fill: var(--font-color);
    transition: fill 0.3s ease-in;
    margin-bottom: 3px;
}

.Active {
    background-color: var(--action-color);
}
.Active span {
    color: #fff;
}
.Active svg {
    fill: #fff;
}
