.ZoomToGeolocation {
    width: 30px;
    height: 30px;
    background: #fff;
    position: absolute;
    top: 70px;
    left: 10px;
    cursor: pointer;
    border-radius: 4px;

    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
}

.ZoomToGeolocation svg {
    width: 16px;
    height: 16px;
    fill: var(--font-color);
}

.ZoomToGeolocation:hover,
.ZoomToGeolocation:focus {
    background-color: #f4f4f4;
}
