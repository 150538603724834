.LocationDetails {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.ImageCarousel {
    z-index: 1;
    position: relative;
    height: 30%;
}

.Swiper {
    width: 100%;
    height: 100%;
}

:local(.Swiper) :global(.swiper-slide) {
    overflow: hidden;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Swiper img {
    max-height: 100%;
    max-width: 100%;
}

.Actions {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    box-shadow: 0 0 10px 0 rgb(94 92 154 / 50%);
    margin-bottom: 10px;
}

.Action {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex: 1;
    padding: 15px;
}

.Action svg {
    width: 20px;
    fill: var(--font-color);
}

.SmallIconWrapper svg {
    width: 14px;
}

.Action:not(:last-child):after {
    content: '';
    position: absolute;
    top: 10px;
    right: 0;
    width: 1px;
    height: 30px;
    background: var(--border-color);
}

.Title {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.Title svg {
    width: 18px;
    fill: var(--accent-color);
}

.Text {
    padding: 0 20px;
    overflow: auto;
    flex: 1;
    box-sizing: border-box;
}
