.MapRoutes {
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    overflow: auto;
    height: 95px;
    transition: height 0.3s ease-in;
}

.ShowEdit.MapRoutes {
    height: 50vh;
}

.InfoList {
    display: flex;
    align-items: center;
    gap: 30px;
}

.Info span {
    display: block;
    text-align: center;
}
.Info span:first-child {
    font-weight: 700;
    color: var(--font-color-heading);
    font-size: var(--large-font-size);
}

.Info {
    flex: 1;
}

.ButtonsWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    gap: 10px;
}

.EditButton,
.DrivingButton,
.WalkingButton {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: solid 1px var(--border-color);
    background-color: var(--background-color);
    transition: background-color 0.3s ease-in;
}

.ShowEdit .EditButton {
    background-color: var(--accent-color);
}

.EditButton svg {
    width: 14px;
    fill: var(--font-color-heading);
    transition: fill 0.3s ease-in;
}
.DrivingButton svg {
    width: 16px;
    fill: var(--font-color-heading);
    transition: fill 0.3s ease-in;
}
.WalkingButton svg {
    width: 12px;
    fill: var(--font-color-heading);
    transition: fill 0.3s ease-in;
}

.ShowEdit .EditButton svg {
    fill: #fff;
}

.Active.DrivingButton,
.Active.WalkingButton {
    background-color: var(--action-color);
}
.Active.DrivingButton svg,
.Active.WalkingButton svg {
    fill: #fff;
}

.Locations {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.MapRoute {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
}

.Arrows svg {
    width: 18px;
    fill: var(--font-color-heading);
}

.MapRoute > span {
    flex: 1;
}
.MapRoute.Removed > span {
    text-decoration: line-through;
}

.Arrows {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.RemoveFromRoute {
    width: 14px;
    fill: var(--danger-color);
}
.AddToRoute {
    display: none;
    width: 14px;
    fill: var(--success-color);
}

.MapRoute.Removed .RemoveFromRoute {
    display: none;
}

.MapRoute.Removed .AddToRoute {
    display: block;
}

.LearnMoreError {
    display: flex;
    align-items: center;
}

.LearnMoreError > span {
    color: #fff;
}

.LearnMoreButton {
    border: 1px solid #000;
    border-radius: 3px;
    color: #000 !important;
    cursor: pointer;
    font-weight: 700;
    margin-right: 10px;
    padding: 5px;
}
