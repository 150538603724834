.SearchWrapper {
    position: relative;
}

.Search {
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: var(--background-color);
}

.Search > svg {
    width: 16px;
    fill: var(--font-color);
}

.Search .Caret {
    width: 12px;
    margin-left: auto;
    transform: rotate(180deg);
}
.Search .CaretDown {
    transform: rotate(0deg);
}

.Search input {
    border: none;
    background-color: var(--background-color);
    font-weight: 700;
}
.Search input:focus-visible {
    border: none;
    outline: none;
}

.SearchResults {
    display: none;
    padding: 0 0 10px;
    border-bottom: solid 1px var(--border-color);
    position: absolute;
    top: 60px;
    z-index: 10000;
    background: var(--background-color);
    width: 100%;
    box-sizing: border-box;
    max-height: 35vh;
    overflow: auto;
}
.Show.SearchResults {
    display: block;
}

.FilteredLocation {
    padding: 3px 20px;
}

.NoResults {
    padding: 3px 20px;
}
