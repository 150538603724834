.Footer {
    background-color: #292b30;
    padding: 25px 20px;
    margin-top: auto;
}

.Footer > p {
    color: #fff;
    font-style: italic;
    font-size: var(--small-font-size);
}
.Footer > p:first-child {
    margin-top: 0;
}
.Footer > p:last-child {
    margin-bottom: 0;
}
