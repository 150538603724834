.MainButton {
    min-height: 41px;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    outline: none;

    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 0;
    padding: 10px 20px;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    overflow: hidden;
    transition: box-shadow 0.3s ease-out;
    font-size: var(--normal-font-size);
}

.MainButton.FullWidth {
    width: 100%;
}

.MainButton.Narrow {
    width: 170px;
}

.MainButton.VeryNarrow {
    width: 70px;
}

.MainButton svg {
    width: 17px;
    height: 17px;
    margin-right: 10px;
}

.MainButton[color='action'] {
    background-color: var(--action-color);
    box-shadow: 0 10px 20px -10px rgb(94 94 84 / 59%);
    color: #fff;
}

.MainButton[color='success'] {
    background-color: var(--success-color);
    box-shadow: 0 10px 20px -10px rgba(0, 171, 85, 0.59);
    color: #fff;
}

.MainButton[color='warning'] {
    background-color: var(--warning-color);
    box-shadow: 0 10px 20px -10px rgba(226, 160, 63, 0.59);
    color: #fff;
}

.MainButton[color='danger'] {
    background-color: var(--danger-color);
    box-shadow: 0 10px 20px -10px rgba(231, 81, 90, 0.59);
    color: #fff;
}

.MainButton[color='info'] {
    background-color: #805dca;
    box-shadow: 0 10px 20px -10px rgb(92 26 195 / 59%);
    color: #fff;
}

.MainButton[color='grey'] {
    background-color: #bfc9d4;
    box-shadow: 0 10px 20px -10px rgb(191 201 212 / 59%);
    color: #3b3f5c;
}

.MainButton:hover {
    box-shadow: none;
}
.MainButton.Loading {
    pointer-events: none;
    opacity: 0.8;
    box-shadow: none;
}

.MainButton.Loading svg {
    animation: spin 3s linear infinite;
}
.MainButton:focus::after {
    opacity: 0;
}

.MainButton.Circle {
    width: 41px;
    height: 41px;
    border-radius: 50%;
    padding: 10px;
}
.MainButton.Circle svg {
    margin: 0;
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* Dark */
body[data-theme='dark'] .MainButton[color='grey'] {
    background-color: #1b2e4b;
    box-shadow: 0 10px 20px -10px rgb(27 46 75 / 59%);
    color: #bfc9d4;
}
