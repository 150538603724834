.OfflineWrapper {
    height: 100%;
}

.OfflineContent {
    padding: 15px 20px;
    background: var(--background-color);
    border-radius: 6px;
    margin-top: 0;
    position: fixed;
    top: 0;
    z-index: 4;
    left: calc(50% - 200px);
    transform: translateY(-100%);
    transition: transform 0.5s ease-in-out, margin-top 0.5s ease-in-out;
    box-sizing: border-box;
}

@media only screen and (max-width: 500px) {
    .OfflineContent {
        width: calc(100% - 40px);
        left: 20px;
    }
}

.OfflineWrapper.IsOffline .OfflineContent {
    transform: translateY(0);
    margin-top: 40px;
}

.Overlay {
    position: fixed;
    z-index: 3;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    pointer-events: none;
}
.OfflineWrapper.IsOffline .Overlay {
    opacity: 1;
    pointer-events: unset;
}

.OfflineText h3 {
    margin: 0;
    font-weight: 700;
}

.OfflineButtonWrapper button {
    display: block;
    width: 100%;
    border: 0;
    margin-top: 20px;
    padding: 10px;
    font-family: var(--font-family);
    color: var(--font-color);
    font-size: var(--normal-font-size);
    font-weight: 600;
    cursor: pointer;
    background-color: var(--action-color);
    color: #fff;
    border-radius: 6px;
}

.OfflineContent h2 {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px var(--border-color);
}
