.ModalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
    z-index: 100000;
}

.ModalWrapper.Show {
    opacity: 1;
    pointer-events: all;
}

.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 0;
    background: linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgb(22, 28, 36) 100%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
}

.ModalWrapper.Show .Backdrop {
    opacity: 1;
}

.Modal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1002;
    position: fixed;
}

.ModalInner {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}

.ModalWrapper.Show .ModalInner {
    transform: none;
}

.ModalWrapper.Small .ModalInner {
    width: 620px;
    max-width: 100%;
    max-height: 100%;
    height: unset;
}

.CloseButtonWrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    width: 24px;
    height: 24px;
    background: #fff;
    box-shadow: 0 0 10px 0 rgb(94 92 154 / 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.CloseButtonWrapper svg {
    width: 10px;
    fill: var(--font-color-heading);
}

.ModalHeader h3 {
    margin: 0;
    color: var(--font-color-heading);
}
.ModalHeader span {
    margin-top: 2px;
    color: #888ea8;
}
.ModalHeader svg {
    width: 20px;
    height: 20px;
}

.ModalContent {
    height: 100%;
    overflow: auto;
}

.ModalFooter {
    padding: 18px 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #e0e6ed;
}

.Loading {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Spinner {
    opacity: 0;
    animation: spin 3s linear infinite;
}
.Spinner.Show {
    opacity: 1;
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
