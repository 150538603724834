.Page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.PageContent {
    padding: 20px 0;
    margin-bottom: 40px;
}
.PageContent > h1 {
    padding: 0 20px;
    margin-bottom: 10px;
}
.PageContent > h3 {
    padding: 0 20px;
    margin-top: 20px;
}

.Description {
    padding: 0 20px;
}

.HomeImage > img {
    width: 100%;
}

.Actions {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    box-shadow: 0 0 10px 0 rgb(94 92 154 / 50%);
    margin-bottom: 10px;
    margin-top: -5px;
}

.Action {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex: 1;
    padding: 15px;
}

.Action svg {
    width: 20px;
    fill: var(--font-color);
}

.SmallIconWrapper svg {
    width: 14px;
}

.Action:not(:last-child):after {
    content: '';
    position: absolute;
    top: 10px;
    right: 0;
    width: 1px;
    height: 30px;
    background: var(--border-color);
}
