.LogoWrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: #fff;
    border-bottom: solid 1px var(--border-color);
}
.LogoWrapper img {
    width: 230px;
}

.Accessibility {
    margin-left: auto;
    width: 35px;
    fill: var(--accent-color);
    cursor: pointer;
}
