.MapContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.Map {
    flex: 1;
}
.Map > div {
    height: 100%;
}

:global(.leaflet-tooltip) {
    padding: 2px 6px;
    margin-left: 15px;
}

.BlueCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    left: -1.5rem;
    top: 0rem;
    position: relative;
    border-radius: 50%;
}

.BlueCircle svg {
    width: 10px;
    fill: #fff;
}

.BlueCircle {
    background: rgba(65, 149, 228, 1);
    box-shadow: 0 0 0 0 rgba(65, 149, 228, 1);
    animation: pulse-blue 2s infinite ease-in-out;
}

@keyframes pulse-blue {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(65, 149, 228, 0.5);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(65, 149, 228, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(65, 149, 228, 0);
    }
}
