.Layout {
    height: 100%;
}

.ContentWrapper {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Content {
    flex: 1 1;
    width: 100%;
    overflow: auto;
}

.ContentWrapper.NoPadding .Content {
    padding: 0;
    width: 100%;
}

.NoOverflow .Content {
    overflow: hidden;
}

.Scroller > div {
    display: flex;
    flex-direction: column;
}

.ScrollWrapper {
    flex: 1;
    overflow: auto;
}

.LoginPopup {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;

    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    overflow: auto;
}
.LoginPopupInner {
    width: 500px;
    max-width: 100%;
    max-height: 100%;
    position: relative;
}
.ClosePopup {
    position: absolute;
    text-align: right;
    right: 5px;
    top: 5px;
}
.ClosePopup svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
}
:local(.Layout):global(.IsMobile) :local(.LoginPopupInner) {
    width: 100%;
    height: 100%;
}
