.LoaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--background-color);
    z-index: 1;
    pointer-events: none;
    opacity: 0;
}
.Show.LoaderWrapper {
    pointer-events: all;
    opacity: 1;
}

.LoaderWrapper:not(.Show) {
    transition: opacity 0.2s ease-in;
}

.Loader {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.Text {
    margin-top: 30px;
    text-align: center;
}
.Text h1 {
    margin-bottom: 5px;
}
.Text p {
    margin: 0;
}

.LoadingWrapper {
    position: absolute;
    bottom: 50px;
    display: flex;
    align-items: center;
    background: var(--action-color);
    padding: 10px 35px 10px 20px;
    border-radius: 6px;
}

.Loading {
    margin-right: 30px;
    color: #fff;
    font-weight: 700;
}

/* Dots animation */
.DotPulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
}
.DotPulse::before,
.DotPulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
}
.DotPulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
}
.DotPulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
    0% {
        box-shadow: 9984px 0 0 -5px;
    }
    30% {
        box-shadow: 9984px 0 0 2px;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px;
    }
}
@keyframes dot-pulse {
    0% {
        box-shadow: 9999px 0 0 -5px;
    }
    30% {
        box-shadow: 9999px 0 0 2px;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px;
    }
}
@keyframes dot-pulse-after {
    0% {
        box-shadow: 10014px 0 0 -5px;
    }
    30% {
        box-shadow: 10014px 0 0 2px;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px;
    }
}
